<template>
  <div id="order">
    <div class="mine-title">{{ $t("route.order") }}</div>
    <van-tabs v-model="currentStatus" color="#642EC7" title-active-color="#642EC7" title-inactive-color="#666666" 
    animated swipeable :ellipsis="false" @change="onChangeTabHandler">
      <van-tab :title="item.title" :name="item.status" v-for="(item,index) in tabs" :key="index">
        <a-spin size="large" :spinning="isLoading">
          <div class="table-page-table-wrapper">
            <a-table
            row-key="id"
            size="middle"
            :columns="columns" 
            :data-source="orders"
            :pagination="pagination" 
            @change="handleTableChange"
            :scroll="{ x: 900 }"
            >
            <template #renderEmpty>
                <p>暂无数据</p>
            </template>
              <template slot="product" slot-scope="text,record">
                <a-space>
                  <div class="product-img">
                    <img :src="record.cover" alt="" srcset="">
                  </div>
                  <div class="two-line-ellipsis" :title="record.name + ' ' + record.spec_value">
                    {{ record.name }}
                    {{ record.spec_value }}
                  </div>
                </a-space>
              </template>
              <template slot="coupon" slot-scope="text,record">
                <span v-if="record.couponcode&&record.coupon_price">
                  - {{ currency }}
                  {{ record.coupon_price }}
                </span>
                <span v-else>—</span>
              </template>
              <template slot="price" slot-scope="text,record">
                <span>
                  {{ currency }}
                  {{ record.real_price }}
                </span>
              </template>
              <template slot="price" slot-scope="text,record">
                <span>
                  {{ currency }}
                  {{ record.real_price }}
                </span>
              </template>
              <template slot="action" slot-scope="text,record">
                <a-button v-if="record.status === 0" type="link" class="btn-link" @click="onPayHandler(record)">
                  {{$t('mine.order_8') }}
                </a-button>
              </template>
            </a-table>
          </div>
          <div class="order-app-wrapper">
            <div class="order-item" v-for="item in orders" :key="item.id">
              <div class="order-header">
               <span>{{ $t("mine.order_7") }}：{{ item.order_no }}</span>
               <span>{{ item.status_name }}</span>
              </div>
              <div class="order-content">
                <div><img width="50" :src="item.cover" alt="" srcset=""></div>
                <div class="order-info">
                  <div>{{ item.name }} </div>
                  <div class="flex-center-between">
                   <span>{{ item.spec_value }}</span>
                   <span class="coupon"  v-if="item.couponcode&&item.coupon_price">
                    - {{ currency }} {{ item.coupon_price }}
                   </span>
                  </div>
                  <div>{{ item.create_at_text }}</div>
                </div>
              </div>
              <div class="order-footer flex-center-between">
                <span>{{ $t("mine.order_3") }}：{{ currency }} {{ item.real_price }}</span>
                <a-button v-if="item.status === 0" type="link" class="btn-link" @click="onPayHandler(item)">
                  {{$t('mine.order_8') }}
                </a-button>
              </div>
            </div>
            <div class="flex" v-show="!isEmpty">
              <a-pagination 
                simple 
                @change="handlePageChange" 
                v-model="pagination.current" 
                :defaultPageSize="pagination.pageSize" 
                :total="pagination.total" 
              />
            </div>
            <div v-show="isEmpty" class="empty-wrapper flex">
              <a-empty :image="require('@/assets/order_none.png')">
                <span slot="description" class="empty-text"> {{$t('mine.order_10')}} </span>
              </a-empty>
            </div>
          </div>
        </a-spin>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Pagination } from 'ant-design-vue';
import {OrderListAPI} from '../api/api'
//0待支付,1已取消,2进行中,3已完成,4已退款,5异常
export default {
  name:'Order',
  components: {
    [Pagination.name]:Pagination,
  },
  data() {
    return {
      tabs:[
        {title:this.$t('mine.tab_1'),status:'all'},
        {title:this.$t('mine.tab_2'),status:1},
        {title:this.$t('mine.tab_3'),status:2},
        {title:this.$t('mine.tab_4'),status:3},
        {title:this.$t('mine.tab_5'),status:4},
      ],
      currentStatus:'all',
      isLoading:false,
      orders:[],
      pagination:{
        current: 1,
        pageSize: 5,
        total:0
      },
    }
  },
  computed:{
    ...mapGetters("config", ["currency"]),
    isEmpty(){
      return this.orders.length <= 0
    },
    columns(){
      return [
        { title: this.$t("mine.order_1"),dataIndex: 'name', scopedSlots: { customRender: 'product' },width: 160 },
        { title: this.$t("mine.order_2"),dataIndex: 'create_at' },
        { title: this.$t("mine.order_11"),dataIndex: 'coupon_price', scopedSlots: { customRender: 'coupon' } },
        { title: this.$t("mine.order_3"),dataIndex: 'real_price', scopedSlots: { customRender: 'price' } },
        { title: this.$t("mine.order_4"),dataIndex: 'status_name' },
        { title: this.$t("mine.order_5"),dataIndex: 'payment', scopedSlots: { customRender: 'payment' }},
        { title: this.$t("mine.order_7"),dataIndex: 'order_no',width: 140 },
        { title: this.$t("mine.order_6"),dataIndex: 'action', scopedSlots: { customRender: 'action' } },
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      this.getOrders()
    },
    async getOrders(){
      this.isLoading = true
      const status = this.currentStatus
      const { pageSize } = this.pagination
      const PARMS = { limit:pageSize,page:1,...(status!=='all'&&{status}) };
      const { data } = await OrderListAPI(PARMS)
      this.isLoading = false
      this.pagination.total = data.count
      this.orders = data.list
    },
    async handleTableChange(pagination){
      this.isLoading = true
      const status = this.currentStatus
      const { current, pageSize } = pagination
      this.pagination = pagination
      const PARMS = { limit:pageSize,page:current,...(status!=='all'&&{status}) };
      const { data } = await OrderListAPI(PARMS)
      this.isLoading = false
      this.orders = data.list
    },
    async handlePageChange(current, pageSize){
      const status = this.currentStatus
      this.pagination.current = current
      const PARMS = { limit:pageSize,page:current,...(status!=='all'&&{status}) };
      const { data } = await OrderListAPI(PARMS)
      this.orders = data.list
    },
    onChangeTabHandler(){
      this.pagination = {current: 1,pageSize: 5,total:0}
      this.getOrders()
    },
    onPayHandler(record) {
      const { name,cover,spec_value,ot_price,price,real_price,dis,dis_spec,coupon_price,order_no } = record
      this.$router.push({
        name: 'Pay',
        query: {
          price,
          real_price,
          ot_price,
          dis,
          dis_spec,
          name,
          cover,
          spec:spec_value,
          order_no,
          coupon_price,
        }
      });
    },

  }
}
</script>
<style lang="scss" scoped>

#order {
  .btn-link{
    color: #B50000;
  }
  .table-page-table-wrapper{
    display: none;
    margin-top: 20px;
    /deep/ .ant-table {
      .ant-table-thead tr th{
        color: #fff;
        background-color: $color;
      }
      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
        background-color: rgba(100, 46, 199, 0.1);
      }
      .product-img{
        width: 50px;
        img{
          border-radius: 4px;
        }
      }
    }
    @media (min-width: 992px) {
      display: block;
    }
  }
  .order-app-wrapper{
    display: none;
    @media (max-width: 992px) {
      display: block;
      margin-top: 14px;
      .order-item{
        font-size: 14px;
        padding: 10px;
        margin-bottom: 16px;
        border-radius: 4px;
        background-color: #fafafa;
        .order-header,.order-footer{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
        }
        .order-content{
          margin: 10px 0;
          display: flex;
          .order-info{
            flex:1;
            margin-left: 10px;
            .coupon{
              color: #B50000;
            }
          }
        }
      }
    }
  }
}

</style>